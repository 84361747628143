import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { UtilsService } from './utils.service';

declare const WindowsAzure: any;

@Injectable()
export class AzureService {
  public clientRef;

  constructor(public domSanitizationService: DomSanitizer, private _utils: UtilsService) {
    this.clientRef = new WindowsAzure.MobileServiceClient(environment.SERVER_URL);
  }

  setCurrentUser = async data => {
    this.clientRef.currentUser = data;
  };

  public getTable(table: string) {
    return this.clientRef.getTable(table);
  }

  public loadOneFromTable(tableId: string, id: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await this.clientRef.getTable(tableId).where({ id: id }).read();

        if (data && data.length > 0) {
          resolve(data[0]);
        } else {
          reject('Nothing to get');
        }
      } catch (e) {
        reject(e);
      }
    });
  }
  public api(resource: string, method: string, data: any): Promise<any> {
    return this.clientRef.invokeApi(resource, { body: data, method: method });
  }
  public post(resource: string, data: any) {
    return this.clientRef.invokeApi(resource, { body: data, method: 'POST' });
  }
  public put(resource: string, data: any) {
    return this.clientRef.invokeApi(resource, { body: data, method: 'PUT' });
  }
  public get(resource: string, data?: any) {
    return this.clientRef.invokeApi(resource, { body: data, method: 'GET' }).then(res => Promise.resolve(res.result));
  }
  public delete(resource: string) {
    return this.clientRef.invokeApi(resource, { method: 'DELETE' });
  }

  public getFile(docId: string, isPreview?: boolean, kind?: string) {
    const token: string = localStorage.getItem('token');
    return this.domSanitizationService.bypassSecurityTrustResourceUrl(
      environment.DATA_ENDPOINT +
        docId +
        '/file?token=' +
        token +
        ('&kind=' + (kind || 'medium')) +
        (isPreview ? '&isPreview=true' : ''),
    );
  }
  public getFileStyle(docId: string, isPreview?: boolean, kind?: string, download?: boolean) {
    const token: string = localStorage.getItem('token');
    return (
      environment.DATA_ENDPOINT +
      docId +
      '/file?token=' +
      token +
      ('&kind=' + (kind || 'medium')) +
      (isPreview ? '&isPreview=true' : '') +
      (download ? '&download=true' : '') +
      (download ? '&lang=' + this._utils.getLanguage() : '')
    );
  }

  public getFileString(docId: string) {
    const token: string = localStorage.getItem('token');
    return environment.DATA_ENDPOINT + docId + '/file?token=' + token;
  }
  public getFilePath(path) {
    const token = localStorage.getItem('token');
    return this.domSanitizationService.bypassSecurityTrustResourceUrl(
      environment.DATA_ENDPOINT + '/' + path + '?token=' + token,
    );
  }

  public regenerate(resource: string, token: any) {
    return this.clientRef.invokeApi(resource, { headers: { token: token }, method: 'GET' });
  }

  public postImage(resource: string, data: any) {
    return this.clientRef.invokeApi(resource, { body: data, method: 'POST' });
  }

  public getAPIUserData() {
    return this.clientRef;
  }
}
