<div class="home">
  <div class="wrapper container">
    <div class="main-panel">
      <app-navbar-cmp></app-navbar-cmp>
    </div>
  </div>
</div>

<div class="page-content" [style.background-image]="imageBg">
  <div class="catalog-container">
    <div class="catalog-list">
      <a class="catalog-item" *ngFor="let item of application?.catalogs" [routerLink]="['/explorer', item.id]">
        <div
          class="folder-container"
          *ngIf="item.kind === 'folder'"
          [ngStyle]="{ 'background-image': 'url(' + _azure.getFileStyle(item.id, true) + ')' }"
        ></div>
      </a>
    </div>
  </div>
</div>
