<footer class="page-footer footer">
  <div class="container">
    <div class="row">
      <div class="col-6 text-left">
        <!-- <img src="../../../assets/img/logo-white.svg" alt="sokoa" /> -->
      </div>
      <div class="col-6 text-right footer-links justify-content-end">
        <span>© 2021 {{ app_name }}</span>
        <!-- <a href="https://www.sokoa.com/fr/mentions-legales.html" target="__blank">Mentions légales</a> -->
      </div>
    </div>
  </div>
</footer>
