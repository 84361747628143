<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="modal-body">
    <div class="row">
      <div class="col">
        <a class="pull-right close" href="javascript:void(0)" (click)="dialogRef.close()"
          ><i class="fa fa-times"></i
        ></a>

        <div *ngIf="!isForgot" class="text-center connexion">
          <img src="{{ imageUrl }}" title="{{ appName }}" />
        </div>
        <span class="bmd-form-group">
          <div class="input-group form-group">
            <input type="email" class="form-control" formControlName="email" placeholder="Email" />
          </div>
        </span>
        <span class="bmd-form-group" *ngIf="!isForgot">
          <div class="input-group form-group">
            <input
              type="password"
              class="form-control"
              formControlName="password"
              [placeholder]="'password' | translate"
            />
          </div>
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div *ngIf="!isForgot" class="justify-content-end pull-right">
          <a href="javascript:void(0)" (click)="isForgot = true" class="btn-forget">
            {{ 'password_forgotten' | translate }}
          </a>
        </div>
        <div *ngIf="isForgot" class="justify-content-start">
          <a href="javascript:void(0)" (click)="isForgot = false" class="btn-forget">
            <i class="fa fa-arrow-left"></i> {{ 'back' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-connexion" [disabled]="isLoading">
      <ng-container *ngIf="!isForgot">
        {{ 'signin' | translate }} &nbsp; <i class="fa fa-caret-right"></i>
      </ng-container>
      <ng-container *ngIf="isForgot">
        {{ 'send' | translate }}
      </ng-container>
    </button>
  </div>
</form>
