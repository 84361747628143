import { jsonIgnoreReplacer, jsonIgnore } from 'json-ignore';

export interface BaseModelInterface {
  id: string;
  version: string;
  createdAt: string;
  updatedAt: string;
  deleted: boolean;
  map(data: any);
  clone(): any;
  prepareForSending(): any;
}

export abstract class BaseModel {
  id: string;
  @jsonIgnore()
  version: string;
  @jsonIgnore()
  createdAt: string;
  @jsonIgnore()
  updatedAt: string;
  @jsonIgnore()
  deleted: boolean;

  constructor(arg?: any) {
    if (arg) {
      for (const key in arg) {
        if (Object.prototype.hasOwnProperty.call(arg, key)) {
          this[key] = arg[key];
        }
      }
    }
  }

  /* Generate a version ready for create or update in DB */
  prepareForSending() {
    const copy: any = {};

    try {
      const data = JSON.parse(JSON.stringify(this, jsonIgnoreReplacer));

      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          if (data[key] === null) {
            // copy[key] = 'null';
          } else {
            copy[key] = data[key];
          }
        }
      }

      delete copy.version;
    } catch (e) {}
    return copy;
  }
  map(data: any) {
    if (data) {
      const keys = Object.keys(this);
      for (const i in keys) {
        if (i) {
          const property = keys[i];
          if (typeof this[property] !== 'function' && !(this[property] instanceof Array)) {
            if (typeof this[property] === 'object' && data[property] !== 'null' && data[property] !== 'undefined') {
              this[property].id = data[property];
            } else if (data.hasOwnProperty(property)) {
              if (typeof this[property] === 'boolean') {
                this[property] = data[property] === true; // === 1 !why?
              } else {
                if (typeof this[property] === 'number' && typeof data[property] === 'string') {
                  const temp: string = data[property];
                  this[property] = parseFloat(temp.replace(',', '.'));
                } else {
                  this[property] = data[property];
                }
              }
            }
          }
        }
      }
    }
    return this;
  }

  hasID() {
    return this.id !== '' && this.id !== null;
  }

  clone(): any {
    const cloneObj = new (<any>this.constructor)();
    const keys = Object.keys(this);
    for (const i in keys) {
      if (i) {
        const property = keys[i];
        if (
          typeof this[property] === 'object' &&
          this[property] &&
          typeof this[property] !== 'function' &&
          !(this[property] instanceof Array) &&
          typeof this[property].clone === 'function'
        ) {
          cloneObj[property] = this[property].clone();
        } else {
          cloneObj[property] = this[property];
        }
      }
    }
    return cloneObj;
  }
}
