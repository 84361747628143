export const environment = {
  production: true,
  SERVER_URL: 'https://api.eurosit.fr/',
  FILE_ENDPOINT: 'https://api.eurosit.fr/api/document/upload',
  DATA_ENDPOINT: 'https://api.eurosit.fr/api/document/',
  APPLICATION_NAME: 'Eurosit',
  LANGUAGES: ['fr', 'en', 'es'],

  // Required for cookies management
  DOMAIN: 'appli.eurosit.fr',

  firebase: {
    apiKey: 'AIzaSyCTV-ciA9qGjYg87LjLUK_WKoEJ7XEJchA',
    authDomain: 'eurosit-3d4df.firebaseapp.com',
    projectId: 'eurosit-3d4df',
    storageBucket: 'eurosit-3d4df.appspot.com',
    messagingSenderId: '880215891375',
    appId: '1:880215891375:web:25acdbf4d3129fc4b1235a',
    measurementId: 'G-P1H7SSD6R3',
  },
};
