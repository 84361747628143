import { Component, OnInit, Inject } from '@angular/core';
import { UtilsService } from '../../shared/providers/utils.service';
import { SessionService } from '../../shared/providers/session.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User } from '../../shared/models/user.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public form: FormGroup;
  isForgot: boolean;
  isLoading: boolean;

  imageUrl: String;
  appName: String;

  constructor(
    private _utils: UtilsService,
    private _session: SessionService,
    private _router: Router,
    private _fb: FormBuilder,
    public dialogRef: MatDialogRef<LoginComponent>,
    private analytics: AngularFireAnalytics,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.form = this._fb.group({
      email: [null, Validators.required],
      password: [null],
    });
    this.isForgot = false;
    this.isLoading = false;

    this.imageUrl = 'assets/img/sokoa-logo.svg';
    this.appName = environment.APPLICATION_NAME;
    if (environment.APPLICATION_NAME === 'Eurosit') {
      this.imageUrl = 'assets/img/eurosit-logo.jpg';
    } else if (environment.APPLICATION_NAME === 'Sellex') {
      this.imageUrl = 'assets/img/sellex-logo.png';
    }
  }

  ngOnInit() {
    this.form.reset();

    // check session
    this._session.getSessionStatus().subscribe(
      session => {
        if (session) {
          this._router.navigate(['/homepage']);
        }
      },
      e => {
        this._session.signout(null);
      },
    );
  }
  submit() {
    if (!this.form.valid) {
      return this._utils.toastWarning(this._utils.translate('incorrect_form'));
    }
    if (!this.isForgot && !this.form.value.password) {
      return this._utils.toastWarning(this._utils.translate('incorrect_form'));
    }
    this.isLoading = true;
    const user = new User({ ...this.form.value });
    if (this.isForgot) {
      this._session.forgotPassword(
        { email: this.form.value.email, appName: environment.APPLICATION_NAME },
        isSuccess => {
          if (isSuccess) {
            this._utils.toastSuccess(
              this._utils.translate('email_send_read_instructions', { email: this.form.value.email }),
            );
            this.isForgot = false;
            this.isLoading = false;
          } else {
            this._utils.toastError();
            this.isLoading = false;
          }
        },
      );
    } else {
      this._session.signin(user, isSuccess => {
        if (isSuccess) {
          this.analytics.setUserId(user.id);
          this.analytics.logEvent('login', { userId: user.id });
          this._utils.toastSuccess(this._utils.translate('you_are_logged'));
          this.isLoading = false;
          this.dialogRef.close(true);
        } else {
          this._utils.toastError(this._utils.translate('incorrect_login'));
          this.isLoading = false;
        }
      });
    }
  }
}
