import { BaseModel } from './base.model';
import { jsonIgnore } from 'json-ignore';

export class ItemTranslation extends BaseModel {
  language: string;
  itemId: string;
  label: string;
  description: string;
  constructor(arg?: any) {
    super(arg);
  }
}
