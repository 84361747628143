import { Injectable } from '@angular/core';
import { environment as ENV } from 'src/environments/environment';
import { Location } from '@angular/common';
import { getUserLocale } from 'get-user-locale';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;
declare var swal: any;

@Injectable()
export class UtilsService {
  constructor(private _location: Location, public _translate: TranslateService) {}

  toastError = (error?: string) => {
    this.showNotification(error || this.translate('an_error_expected'), 'danger');
  };

  toastSuccess = (success?: string) => {
    this.showNotification(success || this.translate('successful_treatment'), 'success');
  };

  toastWarning(warn: string) {
    this.showNotification(warn, 'warning');
  }

  toastInfo(info: string) {
    this.showNotification(info, 'info');
  }

  confirm(text?: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      swal(
        {
          title: text || this.translate('are_you_sure_you_want_to_do_this'),
          imageUrl: './assets/img/icons/ionicons_android-warning.png',
          imageClass: 'contact-form-image pe-7s-culture',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-danger btn-fill btn-round',
          cancelButtonClass: 'btn btn-default btn-fill btn-round',
          confirmButtonText: this.translate('yes'),
          cancelButtonText: this.translate('cancel'),
          reverseButtons: true,
          buttonsStyling: false,
        },
        isConfirmed => {
          if (isConfirmed) {
            resolve(true);
          } else {
            reject();
          }
        },
      );
    });
  }
  popupSuccess(message) {
    return swal({
      title: 'Succès!',
      text: message,
      type: 'success',
      confirmButtonClass: 'btn btn-success',
      buttonsStyling: false,
    });
  }
  back() {
    return this._location.back();
  }
  showNotification(message, type?, from?, align?) {
    if (!type) {
      type = 'info';
    }
    if (!from) {
      from = 'top';
    }
    if (!align) {
      align = 'right';
    }
    $.notify(
      {
        icon: 'notifications',
        message: message,
      },
      {
        type: type,
        timer: 3000,
        placement: {
          from: from,
          align: align,
        },
      },
    );
  }

  // return user language
  getLanguage(): string {
    const userLocale = getUserLocale();

    if (!userLocale) return ENV.LANGUAGES[0];

    const userLang = userLocale.split('-').shift().toLowerCase();

    return ENV.LANGUAGES.includes(userLang) ? userLang : ENV.LANGUAGES[0];
  }

  translate(key: string, params?: any): string {
    return this._translate.instant(key, params);
  }
}
