import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PageLayoutComponent } from './layouts/page-layout/page-layout.component';

import { AuthGuard } from './shared/providers/auth.guard';
import { HomepageComponent } from './pages/homepage/homepage.component';

const routes: Routes = [
  {
    //     path: '',
    //     redirectTo: 'auth/login',
    //     pathMatch: 'full',
    //  }, {
    path: '',
    redirectTo: 'homepage',
    pathMatch: 'full',
  },
  {
    path: 'homepage',
    component: HomepageComponent,
  },
  {
    path: '',
    component: PageLayoutComponent,
    children: [
      {
        path: '',
        // canActivate: [AuthGuard],
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
