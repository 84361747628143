import { BaseModel } from './base.model';
import { jsonIgnore } from 'json-ignore';
import { Right } from './right.model';

export class User extends BaseModel {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  isAdmin: boolean;

  @jsonIgnore()
  get name(): string {
    return this.firstname + ' ' + this.lastname;
  }
  @jsonIgnore()
  rights: Right[];

  isValidEmail() {
    const regex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    return regex.test(this.email);
  }
}
