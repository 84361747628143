<nav #navbar class="navbar navbar-expand-lg navbar-transparent navbar-absolute">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <a class="navbar-brand">
        <img src="{{ imageUrl }}" alt="logo" />
      </a>
    </div>

    <div id="navigation" class="nabButtons">
      <a class="btn btn-just-icon btn-link btn-default" (click)="login()" *ngIf="!session.sessionData.token">
        <i class="fa fa-user-circle-o" aria-hidden="true"></i>
      </a>
      <a class="btn btn-log btn-link btn-default" (click)="logout()" *ngIf="session.sessionData.token">
        <i class="fa fa-sign-out" aria-hidden="true"></i>
      </a>
      <button
        type="button"
        class="btn btn-just-icon btn-link btn-default btn-share"
        (click)="share()"
        [disabled]="_sharerSrv.hasFolder() ? true : null"
      >
        <div *ngIf="_sharerSrv.items.length && !_sharerSrv.hasFolder()" class="counter">
          {{ _sharerSrv.items.length }}
        </div>
        <i class="fa fa-share" aria-hidden="true"></i>
      </button>

      <button type="button" class="btn btn-just-icon btn-link btn-default btn-share" (click)="download()">
        <div *ngIf="_sharerSrv.items.length" class="counter">{{ _sharerSrv.items.length }}</div>
        <i class="fa fa-download" aria-hidden="true"></i>
      </button>
    </div>
  </div>
</nav>
