import { BaseModel } from './base.model';
import { jsonIgnore } from 'json-ignore';

import { environment } from 'src/environments/environment';
import { Item } from './item.model';

export class Application extends BaseModel {
  name: string;
  backgroundImage: string;
  aPropos: string;
  contactEmail: string;
  rootItems: string;

  @jsonIgnore()
  catalogs?: Item[];

  constructor(arg?: any) {
    super(arg);
  }
}
