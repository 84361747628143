import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { TranslateService } from '@ngx-translate/core';
import { NgcCookieConsentService, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';

import { UtilsService } from './shared/providers/utils.service';

@Component({
  selector: 'app-my-app',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  //keep refs to subscriptions to be able to unsubscribe later
  private statusChangeSubscription!: Subscription;

  constructor(
    _translate: TranslateService,
    _utils: UtilsService,
    private ccService: NgcCookieConsentService,
    private analytics: AngularFireAnalytics,
  ) {
    _translate.use(_utils.getLanguage());

    console.warn(_translate.instant('cookie_header'));

    _translate.get('cookie_header').subscribe((translated: string) => {
      ccService.getConfig().content = {
        header: _translate.instant('cookie_header'),
        message: _translate.instant('cookie_message'),
        dismiss: _translate.instant('cookie_dismiss'),
        allow: _translate.instant('cookie_allow'),
        deny: _translate.instant('cookie_deny'),
        link: _translate.instant('cookie_link'),
        policy: _translate.instant('cookie_policy'),
      };

      this.ccService.destroy(); // remove previous cookie bar (with default messages)
      this.ccService.init(this.ccService.getConfig()); // update config with translated messages

      this.analytics.setAnalyticsCollectionEnabled(this.ccService.hasConsented());
    });
  }
  ngOnInit(): void {
    this.statusChangeSubscription = this.ccService.statusChange$.subscribe((event: NgcStatusChangeEvent) => {
      // you can use this.ccService.getConfig() to do stuff...
      console.log(`statusChange: ${JSON.stringify(event)}`);
      this.analytics.setAnalyticsCollectionEnabled(event.status === 'allow');
      if (event.status !== 'allow') {
        this.deleteGACookies();
      }
    });
  }

  deleteGACookies() {
    var cookies = document.cookie.split(';');

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf('=');
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

      if (name.startsWith('_ga')) {
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
      }
    }
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.statusChangeSubscription.unsubscribe();
  }
}
