import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SharerService } from '../../services/sharer.service';
import { AzureService } from '../../providers/azure.service';
import { environment as ENV, environment } from 'src/environments/environment';
import { UtilsService } from '../../providers/utils.service';
import { ItemService } from '../../services/item.service';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-modal-download',
  templateUrl: 'modal-download.component.html',
  styleUrls: ['./modal-download.component.scss'],
})
export class ModalDownloadComponent {
  public state = 'PENDING';
  public progress = 0;
  public total = 0;
  public totalStr = '0MB';
  private downloadRef = null;
  private serverUrl: string = environment.SERVER_URL;
  headers: HttpHeaders;

  constructor(
    public _sharerSrv: SharerService,
    public _azure: AzureService,
    public _modal: MatDialogRef<ModalDownloadComponent>,
    public _utils: UtilsService,
    public _itemSrv: ItemService,
    private _http: HttpClient,
    private analytics: AngularFireAnalytics,
  ) {
    this.headers = new HttpHeaders({ 'zumo-api-version': '2.0.0' });
    this.progress = 0;

    _modal.afterClosed().subscribe(result => {
      if (this.downloadRef) {
        this.downloadRef.unsubscribe();
      }
    });
  }

  close() {
    this._modal.close(true);
  }

  submit() {
    this._modal.disableClose = true;

    const items = this._sharerSrv.getItems();

    let name = items && items.length === 1 ? items[0].label : null;

    this.analytics.logEvent('download', {
      items: this._sharerSrv.getItems().map(i => {
        return { id: i.id, name: i.label };
      }),
    });

    this.download(
      this._sharerSrv.getItems().map(item => item.id),
      name,
    );
  }

  download(catalogIds = [], name) {
    const token = localStorage.getItem('token');

    this.downloadRef = this._http
      .post(
        this.serverUrl + 'api/itemMobileWeb/zip',
        { ids: catalogIds, token, lang: this._utils.getLanguage() },
        {
          headers: this.headers,
          responseType: 'blob',
          observe: 'events',
          reportProgress: true,
        },
      )
      .subscribe(
        event => {
          console.warn(event);

          if (event.type === HttpEventType.Response) {
            this._modal.disableClose = false;
            this.state = 'DONE';
            this.progress = 100;
            this.downloadFile(event.body, name);
            this.close();
            this._sharerSrv.clear();
          } else if (event.type === HttpEventType.DownloadProgress) {
            this.state = 'IN_PROGRESS';
            if (event.loaded) {
              this.progress = Math.round((100 * event.loaded) / this.total);
              this.totalStr = `(${this.formatBytes(event.loaded)}/${this.formatBytes(this.total)})`;

              console.warn('New progress : ' + this.progress);
            }
          } else if (event.type === HttpEventType.ResponseHeader) {
            this.state = 'IN_PROGRESS';
            this.total = Number.parseInt(event.headers.get('X-Expected-Length'));
            this.totalStr = `(0/${this.formatBytes(this.total)})`;
            console.warn('New total ' + this.total);
          } else if (event.type === HttpEventType.Sent) {
            this.state = 'BUFFERING';
          }
        },
        error => {
          this._modal.disableClose = false;
          this.state = 'ERROR';
        },
      );
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  downloadFile(data: any, name) {
    const blob = new Blob([data], { type: 'application/zip' });

    const a = document.createElement('a');
    const objectUrl = URL.createObjectURL(blob);
    a.href = objectUrl;
    a.download = name ? `${name}.zip` : 'archive.zip';
    a.click();
    URL.revokeObjectURL(objectUrl);
  }
}
