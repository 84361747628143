<div class="modal-header">
  <h3 class="modal-title">{{ 'you_can_download_a_zip_title' | translate }}</h3>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <p>{{ 'you_can_download_a_zip' | translate }}</p>

  <ul class="list-group">
    <li class="list-group-item" *ngFor="let item of _sharerSrv.items">
      {{ item.label }}
      <button
        [disabled]="state !== 'PENDING'"
        type="button"
        class="btn btn-just-icon btn-link btn-danger"
        (click)="_sharerSrv.removeItem(item.id)"
      >
        <i class="fa fa-trash"></i>
      </button>
    </li>

    <li class="list-group-item text-center" *ngIf="_sharerSrv.items.length === 0">
      {{ 'no_item_selected' | translate }}
    </li>
  </ul>
</div>

<div class="modal-footer">
  <div class="progress-div">
    <div class="progress-div-test">
      <p *ngIf="state === 'BUFFERING'">{{ 'preparing_archive' | translate }}</p>
      <p *ngIf="state === 'IN_PROGRESS'">{{ 'download_started' | translate }}{{ totalStr }}</p>
      <p *ngIf="state === 'DONE'">{{ 'download_done' | translate }}</p>
      <p *ngIf="state === 'ERROR'">{{ 'download_error' | translate }}</p>
      <mat-progress-bar
        *ngIf="state !== 'PENDING' && state !== 'ERROR'"
        [mode]="state == 'PENDING' ? 'buffer' : 'determinate'"
        [value]="progress"
      >
      </mat-progress-bar>
    </div>
  </div>
  <button
    [disabled]="!_sharerSrv.items.length || (state !== 'PENDING' && state !== 'DONE' && state !== 'ERROR')"
    type="button"
    class="btn btn-primary"
    (click)="submit()"
  >
    {{ 'download_zip' | translate }}
  </button>
</div>
