<div class="modal-header">
  <h3 class="modal-title">{{ 'share_documents_by_email' | translate }}</h3>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <p>{{ 'you_can_send_an_email' | translate }}</p>

  <ul class="list-group">
    <li class="list-group-item" *ngFor="let item of _sharerSrv.items">
      {{ item.label }}
      <button type="button" class="btn btn-just-icon btn-link btn-danger" (click)="_sharerSrv.removeItem(item.id)">
        <i class="fa fa-trash"></i>
      </button>
    </li>

    <li class="list-group-item text-center" *ngIf="_sharerSrv.items.length === 0">
      {{ 'no_item_selected' | translate }}
    </li>
  </ul>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="submit()" [disabled]="!_sharerSrv.items.length">
    {{ 'send_by_email' | translate }}
  </button>
</div>
