import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AzureService } from '../providers/azure.service';
import { Item } from '../models/item.model';
import { ItemTranslation } from '../models/item-translation.model';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { UtilsService } from '../providers/utils.service';

@Injectable({
  providedIn: 'root',
})
export class ItemService {
  private totalCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  totalCount$ = this.totalCount.asObservable();
  private serverUrl: string = environment.SERVER_URL;

  headers: HttpHeaders;

  constructor(private _azure: AzureService, private _http: HttpClient, private _utils: UtilsService) {
    this.headers = new HttpHeaders({ 'zumo-api-version': '2.0.0' });
  }

  load(id: string): Promise<Item> {
    return new Promise(async (resolve, reject) => {
      try {
        const data: any = await this._azure.loadOneFromTable('Item', id);

        const translation = await this._azure
          .getTable('ItemTranslation')
          .where({ itemId: data.id, language: this._utils.getLanguage() })
          .read();

        if (translation && translation.length) {
          data.label = translation[0].label;
        }
        resolve(new Item(data));
      } catch (e) {
        reject(e);
      }
    });
  }
  list(page: number = 0, perPage: number = 10, queryString?: any): Promise<Item[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const token = localStorage.getItem('token');
        let query;
        if (token) {
          query = this._azure
            .getTable('Item')
            .take(perPage)
            .includeTotalCount()
            .skip((page - 1) * perPage);
        } else {
          query = this._azure
            .getTable('Item')
            .take(perPage)
            .includeTotalCount()
            .skip((page - 1) * perPage);
        }

        if (queryString) {
          query.where(queryString);
        }

        query.orderBy('createdAt');

        const data = await query.read();
        // update total count
        this.totalCount.next(data.totalCount);
        resolve(data.map(g => new Item(g)));
      } catch (e) {
        reject(e);
      }
    });
  }
  add(item: Item): Promise<Item> {
    return new Promise(async (resolve, reject) => {
      try {
        const added = await this._azure.getTable('Item').insert(item.prepareForSending());
        resolve(added);
      } catch (e) {
        reject(e);
      }
    });
  }
  update(item: Item): Promise<Item> {
    return new Promise(async (resolve, reject) => {
      try {
        const added = await this._azure.getTable('Item').update(item.prepareForSending());
        resolve(added);
      } catch (e) {
        reject(e);
      }
    });
  }
  addTranslation(itemTranslation: ItemTranslation): Promise<ItemTranslation> {
    return new Promise(async (resolve, reject) => {
      try {
        const added = await this._azure.getTable('ItemTranslation').insert(itemTranslation.prepareForSending());
        resolve(added);
      } catch (e) {
        reject(e);
      }
    });
  }
  updateTranslation(itemTranslation: ItemTranslation): Promise<ItemTranslation> {
    return new Promise(async (resolve, reject) => {
      try {
        const added = await this._azure.getTable('ItemTranslation').update(itemTranslation.prepareForSending());
        resolve(added);
      } catch (e) {
        reject(e);
      }
    });
  }
  listItemTranslations(id): Promise<ItemTranslation[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const query = this._azure.getTable('ItemTranslation').take(10).includeTotalCount().skip(0);
        query.where({ itemId: id });
        query.orderBy('createdAt');
        const data = await query.read();
        resolve(data.map(g => new ItemTranslation(g)));
      } catch (e) {
        reject(e);
      }
    });
  }
  delete(right: Item): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        await this._azure.getTable('Item').del({ id: right.id });
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  }

  listNewRoute(page: number = 0, perPage: number = 10, parentItemId: string): Promise<any> {
    const token = localStorage.getItem('token');
    const params: any = {
      lang: this._utils.getLanguage(),
      parentItemId: parentItemId || null,
      $skip: (page - 1) * perPage,
      $top: perPage,
    };

    if (token) params.token = token;

    return this._http
      .get(this.serverUrl + 'api/itemMobileWeb/v2', {
        headers: this.headers,
        params,
      })
      .toPromise()
      .then((data: any) => {
        return data.result;
      });
  }

  async getHistory(catalogIds: Array<string>) {
    try {
      return await Promise.all(catalogIds.map(i => this.load(i)));
    } catch (e) {
      console.error('Error on getHistory', e);
      throw e;
    }
  }
}
