import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SharerService } from '../../services/sharer.service';
import { AzureService } from '../../providers/azure.service';
import { environment as ENV } from 'src/environments/environment';
import { UtilsService } from '../../providers/utils.service';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-modal-share',
  templateUrl: 'modal-share.component.html',
  styleUrls: ['./modal-share.component.scss'],
})
export class ModalShareComponent {
  constructor(
    public _sharerSrv: SharerService,
    public _azure: AzureService,
    public _modal: MatDialogRef<ModalShareComponent>,
    public _utils: UtilsService,
    private analytics: AngularFireAnalytics,
  ) {}

  close() {
    this._modal.close(true);
  }

  submit() {
    this.analytics.logEvent('share', {
      items: this._sharerSrv.getItems().map(i => {
        return { id: i.id, name: i.label };
      }),
    });

    const subject = this._utils.translate('email_subject', { name: ENV.APPLICATION_NAME });
    const body = `${this._utils.translate('email_body_1')}\n
    ${this._utils.translate('email_body_2')}\n
    ${this._utils.translate('email_body_3')}\n
    ${this._utils.translate('email_body_4', { name: ENV.APPLICATION_NAME })}
    ${this._utils.translate('email_body_5')}

    ${this._sharerSrv
      .getItems()
      .map(i => {
        return `- ${i.label} : ${ENV.DATA_ENDPOINT + i.id}/file?download=true&lang=${this._utils.getLanguage()}`;
      })
      .join('\r\n')}

    ${this._utils.translate('email_body_6')}`;

    window.open(`mailto:?&subject=${subject}&body=${encodeURIComponent(body)}`, '_blank');
    this.close();
    this._sharerSrv.clear();
  }
}
