import { BaseModel } from './base.model';
import { jsonIgnore } from 'json-ignore';
import { environment } from 'src/environments/environment';

export class Item extends BaseModel {
  path: string;
  isPublished: boolean;
  kind: string;
  size: number;
  filePath: string;
  parentItemId: string;
  description: string;
  @jsonIgnore()
  label: string;
  @jsonIgnore()
  labels: string;
  @jsonIgnore()
  _title: string;

  @jsonIgnore()
  _selected: boolean;

  @jsonIgnore()
  get title(): string {
    if (this._title) {
      return this._title;
    } else {
      if (this.labels && this.labels.length > 0) {
        const labels = JSON.parse(this.labels);
        this._title = labels[0].label;
      } else {
        this._title = '';
      }
      return this._title;
    }
    return this._title;
  }
  @jsonIgnore()
  hover: boolean;

  @jsonIgnore()
  get kindIcon(): string {
    return Item.kindEnumValues.find(i => i.id === this.kind).icon;
  }
  @jsonIgnore()
  get kindLabel(): string {
    return Item.kindEnumValues.find(i => i.id === this.kind).name;
  }

  @jsonIgnore()
  get imageUrl(): string {
    const token: string = localStorage.getItem('token');

    if (this.kind === 'image') {
      return environment.DATA_ENDPOINT + this.id + '/file?token=' + token + '&kind=big';
    }
    return null;
  }

  constructor(arg?: any) {
    super(arg);
  }
}

export namespace Item {
  export enum KindEnum {
    folder = 'folder',
    image = 'image',
    video = 'video',
    pdf = 'pdf',
  }
  export const kindEnumValues = [
    { id: Item.KindEnum.folder, name: 'Dossier', icon: 'fa-folder-o' },
    { id: Item.KindEnum.image, name: 'Image', icon: 'fa-photo' },
    { id: Item.KindEnum.video, name: 'Vidéo', icon: 'fa-file-video-o' },
    { id: Item.KindEnum.pdf, name: 'Fichier', icon: 'fa-file-o' },
  ];
}
