<div class="wrapper container">
  <div class="main-panel">
    <app-navbar-cmp></app-navbar-cmp>
    <div class="page-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<app-footer></app-footer>
