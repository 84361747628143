import { Component, OnInit } from '@angular/core';
import { ApplicationService } from 'src/app/shared/services/application.service';
import { Item } from 'src/app/shared/models/item.model';
import { AzureService } from 'src/app/shared/providers/azure.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { environment } from 'src/environments/environment';
import { Application } from 'src/app/shared/models/application.model';

import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit {
  isLoading: boolean;
  imageBg: SafeResourceUrl;
  application: Application;
  primaryFolder: Item;
  catalogs: Item[];

  constructor(
    private _appSrv: ApplicationService,
    public _azure: AzureService,
    private _sanitizer: DomSanitizer,
    private analytics: AngularFireAnalytics,
  ) {
    this.isLoading = false;
    analytics.logEvent('home', { appName: environment.APPLICATION_NAME });
  }

  async ngOnInit() {
    await this.loadData();
  }

  async loadData() {
    this.isLoading = true;

    try {
      this.application = await this._appSrv.load();
      this.imageBg = this.getBackgroundFile(this.application.backgroundImage);
    } catch (e) {
      console.log(e);
    }

    this.isLoading = false;
  }

  getBackgroundFile(docId) {
    const urlFile = environment.DATA_ENDPOINT + docId + '/file?token=null&kind=big';
    return this._sanitizer.bypassSecurityTrustStyle(`url(${urlFile})`);
  }
}
