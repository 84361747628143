import { Injectable } from '@angular/core';
import { Item } from 'src/app/shared/models/item.model';

@Injectable({
  providedIn: 'root',
})
export class SharerService {
  public items: Item[];

  constructor() {
    this.clear();
  }

  clear() {
    this.items = [];
  }

  getItems() {
    return [...this.items];
  }

  hasFolder() {
    return this.items.find(i => i.kind === 'folder');
  }

  isSelected(id: string) {
    return this.items.find(i => i.id === id);
  }

  addItem(item: Item) {
    this.items.push(item);
  }

  removeItem(id: string) {
    const index = this.items.findIndex(i => i.id === id);
    if (index >= 0) {
      this.items.splice(index, 1);
    }
  }
}
