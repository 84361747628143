import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import { Application } from '../models/application.model';
import { environment } from '../../../environments/environment';
import { Item } from '../models/item.model';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  headers: HttpHeaders;

  constructor(private _http: HttpClient) {
    this.headers = new HttpHeaders({ 'zumo-api-version': '2.0.0' });
  }

  async load(): Promise<Application> {
    return this._http
      .get(environment.SERVER_URL + 'tables/application', {
        headers: this.headers,
        params: { $filter: `name eq '${environment.APPLICATION_NAME}'` },
      })
      .toPromise()
      .then(async (data: Application[]) => {
        if (!data || !data.length || data.length === 0) throw 'Application not found';

        const app = new Application(data.shift());
        app.catalogs = [];

        if (app.rootItems) {
          const data: any = await this._http
            .get(environment.SERVER_URL + 'api/itemMobileWeb/v2', {
              headers: this.headers,
              params: { rootParentId: app.rootItems, parentItemId: null },
            })
            .toPromise();

          if (data && data.result) {
            app.catalogs = data.result.map((i: Item) => new Item(i));
          }
        }

        return app;
      });
  }
}
