import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { PageLayoutComponent } from './layouts/page-layout/page-layout.component';

import { AuthGuard } from './shared/providers/auth.guard';
import { UtilsService } from './shared/providers/utils.service';
import { AzureService } from './shared/providers/azure.service';
import { SessionService } from './shared/providers/session.service';

import { HomepageComponent } from './pages/homepage/homepage.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ModalShareComponent } from './shared/components/modal-share/modal-share.component';
import { ModalDownloadComponent } from './shared/components/modal-download/modal-download.component';
import { SharerService } from './shared/services/sharer.service';
import { LoginComponent } from './auth/login/login.component';
import { VideoPlayerComponent } from './pages/explorer/video-player/video-player.component';
import { MiniSiteComponent } from './pages/explorer/mini-site/mini-site.component';
import { SafeHtmlPipe } from './pages/pipes/safe-html.pipe';
import { SafeUrlPipe } from './pages/pipes/safe-url.pipe';

import { environment as ENV, environment } from 'src/environments/environment';

import { AngularFireAnalyticsModule, DEBUG_MODE } from '@angular/fire/analytics';
import { AngularFireModule } from '@angular/fire';

import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';

@NgModule({
  exports: [
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
  ],
  imports: [
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    NgcCookieConsentModule.forRoot({
      cookie: {
        domain: environment.DOMAIN,
      },
      palette: {
        popup: {
          background: '#000',
        },
        button: {
          background: '#f1d600',
        },
      },
      layout: 'my-custom-layout',
      layouts: {
        'my-custom-layout': '{{message}}{{compliance}}',
      },
      theme: 'edgeless',
      type: 'opt-out',
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
  ],
  declarations: [],
})
export class MaterialModule {}

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    PageLayoutComponent,
    HomepageComponent,
    NavbarComponent,
    FooterComponent,
    LoginComponent,
    ModalShareComponent,
    ModalDownloadComponent,
    VideoPlayerComponent,
    MiniSiteComponent,
    SafeHtmlPipe,
    SafeUrlPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      defaultLanguage: ENV.LANGUAGES[0],
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy }, // use hash
    AuthGuard,
    UtilsService,
    AzureService,
    SessionService,
    SharerService,
    { provide: DEBUG_MODE, useValue: true },
  ],
  exports: [NavbarComponent, FooterComponent, TranslateModule],
  entryComponents: [
    LoginComponent,
    VideoPlayerComponent,
    MiniSiteComponent,
    ModalShareComponent,
    ModalDownloadComponent,
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
